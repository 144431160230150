import request from '@/plugins/http'

export function apiSaveDepartment(param) {
    return request.post('/api/setting/saveDepartment', param);
}

export function apiGetDepartmentList(param) {
    return request.post('/api/setting/getDepartmentList', param);
}

export function apiValidDepartment(param) {
    return request.post('/api/setting/validDepartment', param);
}

export function apiInValidDepartment(param) {
    return request.post('/api/setting/invalidDepartment', param);
}

export function apiDeleteDepartmentInfo(param) {
    return request.post('/api/setting/deleteDepartmentInfo', param);
}

export function apiGetDepartmentAll(param) {
    return request.post('/api/setting/getDepartmentAll', param);
}






