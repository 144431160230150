import {apiGetLogisticsAll,} from "@/api/setting/logisticsSetting";

import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 1000 * 60 * 30;
let cache_key = 'logistics-info';


export async function getLogisticsAll() {
    const logistics = await well_cache.getSync(cache_key);

    if (logistics.data != null) {
        if (parseInt(logistics.data.time) + cache_time < parseInt(new Date().getTime())){
            await resetLogisticsAll();
        }
        return JSON.parse(logistics.data.results);
    } else {
        return resetLogisticsAll();
    }
}

//更新物流供应商信息
export async function resetLogisticsAll() {
    let res = await apiGetLogisticsAll();

    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
    }

    return res.data.results;
}
