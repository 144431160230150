import {apiGetDepartmentAll} from "@/api/setting/departmentSetting";

import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 1000 * 60 * 30;
let cache_key = 'department-info';

export async function getDepartmentAll() {
    const department = await well_cache.getSync(cache_key);

    if (department.data != null) {
        if (parseInt(department.data.time) + cache_time < parseInt(new Date().getTime())) {
            await resetDepartmentAll();
        }
        return JSON.parse(department.data.results);
    } else {
        return resetDepartmentAll();
    }
}

export async function resetDepartmentAll() {
    let res = await apiGetDepartmentAll();

    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
    }

    return res.data.results;
}
